import React, { useEffect, useState } from 'react';
import { MapComponent } from './components/mapABN';
import * as XLSX from 'xlsx';
import filePath from './data/companiesSuburbs.xlsx';

function App() {
    const [data, setData] = useState(null);

    useEffect(() => {
        fetch(filePath)
            .then(response => response.arrayBuffer())
            .then(buffer => {
                const workbook = XLSX.read(new Uint8Array(buffer), { type: 'array' });

                const allData = [];
                workbook.SheetNames.forEach(sheetName => {
                    if (sheetName !== "Sheet1" || sheetName !== "Not curent names") {
                        const sheet = workbook.Sheets[sheetName];
                        const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                        rows.forEach(([name, state, postcode, suburb]) => {
                            allData.push({ name, state, postcode, suburb });
                        });
                    }
                });

                setData(allData);
            })
            .catch(error => console.error('Error reading the xlsx file:', error));
    }, []);

    return (
        <div>
            {data ? <MapComponent data={data} /> : <p>Loading data...</p>}
        </div>
    );
}

export default App;
