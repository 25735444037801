import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Circle, Popup } from 'react-leaflet';
import { scaleSequential } from 'd3-scale';
import { interpolateYlOrRd } from 'd3-scale-chromatic';
import 'leaflet/dist/leaflet.css';
import postcodesData from '../data/postcodes.json'; // Adjust the path as needed

function getCoordinates(postcode) {
    const postcodeInfo = postcodesData.find(item => item.postcode === postcode);
    if (postcodeInfo) {
        return [postcodeInfo.lat, postcodeInfo.long];
    }
    return [0, 0]; // Default coordinates if postcode not found
}

export function MapComponent({ data }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, [data]);

    // Group company data by postcode and store suburb information
    const postcodeData = data.reduce((acc, { name, state, postcode, suburb }) => {
        if (!acc[postcode]) {
            acc[postcode] = { companies: [], suburb };
        }
        acc[postcode].companies.push(name);
        return acc;
    }, {});

    // Determine max and min counts for color scaling
    const counts = Object.values(postcodeData).map(({ companies }) => companies.length);
    const maxCount = Math.max(...counts);
    const minCount = Math.min(...counts);

    // Adjusted color scale with a more granular range
    const colorScale = scaleSequential()
        .domain([minCount, maxCount])
        .interpolator(interpolateYlOrRd);

    if (loading) {
        return <p>Loading map...</p>;
    }

    return (
        <MapContainer center={[-35.208765201860125, 149.1352703688162]} zoom={10} style={{ height: '100vh', width: '100vw' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {Object.entries(postcodeData).map(([postcode, { companies, suburb }]) => {
                const [lat, lng] = getCoordinates(postcode);
                const companyCount = companies.length;

                return (
                    <Circle
                        key={postcode}
                        center={[lat, lng]}
                        radius={companyCount * 60} // Increase size for better visibility
                        fillColor={colorScale(companyCount)}
                        color="black" // Black border color
                        fillOpacity={0.6} // Slightly more opaque for better visibility
                    >
                        <Popup>
                            <div style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
                                <h3>Postcode: {postcode}, Suburb: {suburb}</h3>
                                <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
                                    {companies.map((company, index) => (
                                        <li key={index}>{company}</li>
                                    ))}
                                </ul>
                            </div>
                        </Popup>
                    </Circle>
                );
            })}
        </MapContainer>
    );
}
